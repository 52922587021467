import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Icons from '../icons'
import { RadioButton } from '../radio-button'

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 130%;
  left: -14%;
  width: 225px;
  background: #2a3149;
  padding: 9px 16px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  z-index: 99;
  border-radius: 3px;

  animation: ${appear} 0.2s ease-in-out;
  animation-fill-mode: forwards;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    right: 0%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-bottom-color: #2a3149;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff;
  opacity: 0.1;
`

function useOutsideAlerter(ref, callback) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function renderAmount(balanceCents) {
  if (!balanceCents || typeof balanceCents !== 'number') {
    return 0
  }

  const balance = balanceCents / 100 || 0
  const amount = balance.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    decimals: 'auto',
  })

  return amount
}

export function DropDown(props) {
  const wrapperRef = React.useRef(null)

  const translate = Herz.I18n.useTranslate()

  useOutsideAlerter(wrapperRef, props.onClose)

  if (!props.open) {
    return null
  }

  return (
    <Wrapper ref={wrapperRef}>
      {props.fetching ? (
        <Icons.Spinner />
      ) : (
        <Common.Box display="flex" flexDirection="column" width="100%">
          <Common.Box
            my={0}
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Common.Box ml={0} display="flex" flexDirection="row" width="100%">
              <Icons.GoldCoin />
              {!props.gameplay && (
                <Common.Box ml={0} fontWeight="bold" color="white">
                  GC
                </Common.Box>
              )}

              <Common.Box ml={0}>
                {props.gameplay
                  ? translate('gold-coins')
                  : renderAmount(props.goldCoins?.balanceCents || 0)}
              </Common.Box>
            </Common.Box>

            <Common.Box width="20px" height="20px" position="relative">
              <RadioButton
                autoFocus={false}
                checked={props.current === 'GCC'}
                disabled={false}
                name="GCC"
                layout="col-half"
                value="GCC"
                variant={'button'}
                onBlur={() => {}}
                onChange={() => props.onCurrencyChage('GCC')}
              />
            </Common.Box>
          </Common.Box>
          <Divider />
          <Common.Box
            my={0}
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Common.Box ml={0} display="flex" flexDirection="row" width="100%">
              <Icons.SweepCoin />
              {!props.gameplay && (
                <Common.Box ml={0} fontWeight="bold" color="white">
                  SC
                </Common.Box>
              )}

              <Common.Box ml={0}>
                {props.gameplay
                  ? translate('sweeps-coins')
                  : renderAmount(props.sweepCoins?.balanceCents || 0)}
              </Common.Box>
            </Common.Box>
            <Common.Box width="20px" height="20px" position="relative">
              <RadioButton
                autoFocus={false}
                checked={props.current === 'SCC'}
                disabled={false}
                name={'SCC'}
                layout="col-half"
                value={'SCC'}
                variant={'button'}
                onBlur={() => {}}
                onChange={() => props.onCurrencyChage('SCC')}
              />
            </Common.Box>
          </Common.Box>
        </Common.Box>
      )}
    </Wrapper>
  )
}

DropDown.propTypes = {
  current: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  goldCoins: PropTypes.object,
  sweepCoins: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCurrencyChage: PropTypes.func.isRequired,
  gameplay: PropTypes.bool.isRequired,
}
