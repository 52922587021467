import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as locks from '@rushplay/compliance/locks'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Constants from './constants'
import * as Icons from './icons'
import * as Player from './player'
import { Balance } from './balance'
import { Button } from './button'
import { MenuItem } from './menu-item'
import { useMenuQueries } from './use-menu-queries'

const HamburgerWrapper = styled.div`
  font-size: 29px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    display: none;
  }
`

const Logotype = styled('div')`
  ${css({
    width: ['150px', null, '170px'],
    height: ['30px', null, '35px'],
    backgroundImage: 'url(/images/logo.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  })};
`

const Wrapper = styled.header`
  ${css({
    backgroundColor: 'nav',
    position: 'sticky',
    top: '0px',
    left: '0px',
    width: ['100%'],
    display: ['flex', 'flex', 'grid'],
    alignItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: ['space-between', 'space-between', 'center'],
    padding: ['0px 5px 0px 0px', '0px 10px'],
    zIndex: '9999',
    height: '50px',
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const Menu = styled.div`
  ${css({
    flexGrow: 1,
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    display: ['none', 'none', 'flex'],
  })};

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const MobileSubMenu = styled.div`
  ${css({
    position: 'fixed',
    backgroundColor: 'nav',
    top: '50px',
    borderTop: '1px solid black',
    flexGrow: 1,
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    display: ['flex', 'none', 'none', 'none'],
    transition: 'all 0.25s',
    zIndex: 109,
    overflow: 'auto',
  })};
  transform: ${({ isHidden }) =>
    isHidden ? 'translateY(-100%)' : 'translateY(0)'};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

export function MainMenu() {
  const { authenticated } = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()
  const { loginQuery, registerQuery, menuQuery } = useMenuQueries()
  const prevScrollPos = React.useRef(null)
  const isSportsbookPage = location.pathname.includes('/sports')
  const isGameplay = location.pathname.includes('/games')

  const [isSubmenuHidden, setIsSubmenuHidden] = React.useState(isGameplay)
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  React.useEffect(() => {
    const isMobile = window.innerWidth <= 768

    function handleScroll() {
      // do nothing if not mobile device, during gameplay or when on sportsbook page
      if (!isMobile || isSportsbookPage || isGameplay) {
        return
      }

      const currentScrollPos = window.scrollY

      const isHidden =
        prevScrollPos.current < currentScrollPos && currentScrollPos > 60
      if (isSubmenuHidden !== isHidden) {
        setIsSubmenuHidden(isHidden)
      }
      prevScrollPos.current = currentScrollPos
    }

    prevScrollPos.current = window.scrollY

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos, isSubmenuHidden, isSportsbookPage, isGameplay])

  React.useEffect(() => {
    const isMobile = window.innerWidth <= 768

    if (isMobile) {
      if (isSportsbookPage || isGameplay) {
        setIsSubmenuHidden(true)
      } else {
        setIsSubmenuHidden(false)
      }
    }
  }, [location.pathname, isSportsbookPage, isGameplay])

  const vipLevel = ReactRedux.useSelector(state =>
    Player.getVipLevel(state.player)
  )
  const locked = ReactRedux.useSelector(
    state => locks.isInitialized(state.locks) && locks.hasLocks(state.locks)
  )
  const translate = Herz.I18n.useTranslate(
    () => [`vip.perks.table.level.${Constants.ConvertedTiers[vipLevel]}.image`],
    [vipLevel]
  )

  if (location.pathname === '/oauth-redirect') {
    // hide menu for redirect page
    return null
  }

  return (
    <React.Fragment>
      <Wrapper data-testid="main-menu">
        {/* Navigations */}
        <Menu>
          <Common.Box
            as="nav"
            display="flex"
            flexGrow="1"
            justifyContent={['center']}
          >
            <MenuItem
              icon={Icons.Casino}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-casino"
              to="/casino"
            >
              {translate('main-menu.casino')}
            </MenuItem>
            <MenuItem
              icon={Icons.LiveCasino}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-casino-live"
              to="/live-casino"
            >
              {translate('main-menu.live-casino')}
            </MenuItem>
            <MenuItem
              icon={Icons.Sports}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-sports"
              to="/sports"
            >
              {translate('main-menu.sports')}
            </MenuItem>
          </Common.Box>
        </Menu>

        <Common.Box
          color="g-text"
          px={0}
          position="sticky"
          top="0px"
          left="0px"
          zIndex="high"
          display="flex"
          justifyContent={['flex-start', 'flex-start', 'center']}
          alignItems={['center']}
          flexDirection={'row'}
          flexShrink="0"
          padding="10px"
        >
          <Common.Box display="flex" justifyContent="center">
            <ReactRouter.Link to="/" data-testid="main-menu-logotype">
              <Logotype title="legendz.com" authenticated={authenticated} />
            </ReactRouter.Link>
          </Common.Box>
        </Common.Box>

        {!authenticated && (
          <Common.Box
            display={'flex'}
            gridGap={0}
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <ReactRouter.Link to={`?${loginQuery}`}>
              <Button variant="secondary" stretch>
                {translate('main-menu.login')}
              </Button>
            </ReactRouter.Link>

            <ReactRouter.Link to={`?${registerQuery}`}>
              <Button variant="primary" stretch>
                {translate('main-menu.register')}
              </Button>
            </ReactRouter.Link>

            <HamburgerWrapper data-testid="main-menu-menu-toggler">
              <ReactRouter.Link to={`?${menuQuery}`}>
                <Icons.Menu />
              </ReactRouter.Link>
            </HamburgerWrapper>
          </Common.Box>
        )}
        {authenticated && !isPhoneVerificationRequired && (
          <Common.Box
            display="flex"
            justifyContent={['flex-end', 'flex-end', 'center']}
            alignItems="center"
            pr={0}
          >
            <Balance gameplay={isGameplay} />
            <HamburgerWrapper data-testid="main-menu-menu-toggler">
              <ReactRouter.Link to={`?${menuQuery}`}>
                <Icons.Menu />
              </ReactRouter.Link>
            </HamburgerWrapper>
          </Common.Box>
        )}
      </Wrapper>
      {isGameplay ? null : (
        <MobileSubMenu isHidden={isSubmenuHidden}>
          <Common.Box
            as="nav"
            display="flex"
            flexGrow="1"
            justifyContent={['center']}
          >
            <MenuItem
              icon={Icons.Casino}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-casino"
              to="/casino"
            >
              {translate('main-menu.casino')}
            </MenuItem>
            <MenuItem
              icon={Icons.LiveCasino}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-casino-live"
              to="/live-casino"
            >
              {translate('main-menu.live-casino')}
            </MenuItem>
            <MenuItem
              icon={Icons.Sports}
              disabled={locked || isPhoneVerificationRequired}
              testId="main-menu-sports"
              to="/sports"
            >
              {translate('main-menu.sports')}
            </MenuItem>
          </Common.Box>
        </MobileSubMenu>
      )}
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'main-menu.login',
    'main-menu.register',
    'main-menu.my-games',
    'main-menu.casino',
    'main-menu.live-casino',
    'main-menu.vip',
    'main-menu.promotion-notifications',
    'account.nav.me-moments',
    'main-menu.support',
    'main-menu.deposit',
    'main-menu.withdraw',
    'main-menu.log-out',
    'badge.new',
    'campaign-tooltip.header',
    'campaign-tooltip.body',
    'main-menu.sports',
    'sweeps-coins',
    'gold-coins',
  ],
  MainMenu
)

// For @loadable/components
export default MainMenu
