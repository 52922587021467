import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

import * as Constants from '../constants'

export function displayPlaceholder(currency) {
  const translate = Herz.I18n.useTranslate()

  if (currency === Constants.CURRENCIES.GCC) {
    return translate('gold-coins')
  }

  return translate('sweeps-coins')
}

displayPlaceholder.propTypes = {
  currency: PropTypes.string.isRequired,
}
