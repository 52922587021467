import { connect } from 'react-redux'

import * as player from '../player'

import { Balance as Component } from './balance'

export const Balance = connect(state => {
  const balance = player.getBalanceCents(state.player)
  const withdrawalBalanceCents = player.getWithdrawableBalanceCents(
    state.player
  )
  const goldCoins = player.getGoldCoins(state.player)
  const sweepCoins = player.getSweepCoins(state.player)

  return {
    currency: player.getCurrency(state.player),
    amountCents: Number.isNaN(balance) ? 0 : balance,
    withdrawalBalanceCents,
    goldCoins,
    sweepCoins,
  }
})(Component)
