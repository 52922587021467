import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Configuration from '../configuration'
import * as Cookies from '../cookies-module'
import * as Icons from '../icons'
import * as Player from '../player'
import { getDomain } from '../get-domain'
import { useMenuQueries } from '../use-menu-queries'

import { DropDown } from './dropdown'
import { displayPlaceholder } from './display-placeholder'

const Wrapper = styled.div`
  position: relative;
  width: 200px;
  height: 40px;
  border-radius: 6px;
  background: #2a3149;
  display: flex;
`

const DepositButton = styled.button`
  cursor: pointer;
  background: linear-gradient(91deg, #7a18dc -85.74%, #5718dc 100%);
  width: 40px;
  height: 40px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #fff;
  padding-right: 0px;
  padding-inline-start: 0px;

  text-align: center;
  font-family: Raleway;
  font-size: 53px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 37.736% */
  outline: none;
  border: none;
`

const Currency = styled.div`
  padding: 11px;
  cursor: pointer;
  font-family: Raleway;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${props => (props.disabled ? 'pointer-events:none' : '')}
`

function DisplayCoins(props) {
  if (props.fetching) {
    return <Icons.Spinner />
  }

  const balance = props.data?.balanceCents / 100 || 0
  const amount = balance.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    decimals: 'auto',
  })

  return (
    <Common.Box display="flex" justifyContent="center" alignItems="center">
      <Common.Box pr={0}>{props.icon}</Common.Box>
      <Common.Text
        style={{ pointerEvents: 'none' }}
        fontSize="14px"
        fontWeight="500"
      >
        {props.gameplay ? displayPlaceholder(props.data.currency) : amount}
      </Common.Text>
    </Common.Box>
  )
}

DisplayCoins.defaultProps = {
  icon: null,
  data: {},
  fetching: false,
}

DisplayCoins.propTypes = {
  icon: PropTypes.node,
  data: PropTypes.object,
  fetching: PropTypes.bool,
  gameplay: PropTypes.bool,
}

const DropDownButton = styled.div`
  ${props =>
    css({
      transform: props.open ? 'rotate(180deg)' : 'rotate(0deg)',
    })}
  transition: transform 0.3s;
  padding-left: 5px;
`

function isGamePlay(path) {
  const pattern = /^\/casino\/games\/\d+$/

  return pattern.test(path)
}

function selectAccount(apiUrl, currency, token) {
  return fetch(`${apiUrl}/select_account`, {
    body: JSON.stringify({ currency }),
    headers: {
      Accept: 'application/vnd.casinosaga.v1',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    method: 'PUT',
  })
}

selectAccount.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

const SPORTSBOOK_TOKEN_QUERY = `
  query SportsbookToken {
    player {
      delasportTokens {
        GCC
        SCC
      }
    }
  }
`

export function Balance(props) {
  const dispatch = ReactRedux.useDispatch()
  const [dropDown, setDropDown] = React.useState(false)
  const { token } = Herz.Auth.useSession()
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const client = Urql.useClient()
  const [, setSportsbookToken] = Cookies.useCookie(
    Constants.CookieKeys.SPORTSBOOK_TOKEN
  )
  const { depositQuery } = useMenuQueries()
  const location = ReactRouterDom.useLocation()

  const [tab, setTab] = React.useState(props.currency)
  function handleDropDown() {
    setDropDown(prevState => !prevState)
  }

  function handleCurrencyChage(currency) {
    // use state to avoid delay in UX feedback
    setTab(currency)
    setDropDown(false)

    // reload page after successfull account selection
    selectAccount(apiUrl, currency, token)
      .then(res => {
        if (res.status === 200) {
          return client.query(SPORTSBOOK_TOKEN_QUERY).toPromise()
        }
      })
      .then(res => {
        const delasportTokens = res?.data?.player?.delasportTokens
        setSportsbookToken(delasportTokens[currency], {
          ...Constants.CookieOptions[Constants.CookieKeys.TOKEN],
          domain: getDomain(),
        })

        return dispatch(
          Player.fetchPlayerInfo({
            success: () => {
              if (isGamePlay(location.pathname)) {
                window.location.reload()
              }
            },
          })
        )
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
      })
  }

  return (
    <Wrapper>
      <DepositButton>
        <ReactRouterDom.Link to={`?${depositQuery}`}>+</ReactRouterDom.Link>
      </DepositButton>
      <Currency disabled={dropDown} onClick={handleDropDown}>
        <DisplayCoins
          gameplay={props.gameplay}
          icon={
            tab === Constants.CURRENCIES.GCC ? (
              <Icons.GoldCoin />
            ) : (
              <Icons.SweepCoin />
            )
          }
          data={
            tab === Constants.CURRENCIES.GCC
              ? props.goldCoins
              : props.sweepCoins
          }
        />
        <DropDownButton open={dropDown}>
          <Icons.Chevron />
        </DropDownButton>
      </Currency>
      <DropDown
        current={tab}
        open={dropDown}
        onClose={() => setDropDown(false)}
        onCurrencyChage={handleCurrencyChage}
        goldCoins={props.goldCoins}
        sweepCoins={props.sweepCoins}
        gameplay={props.gameplay}
      />
    </Wrapper>
  )
}

Balance.propTypes = {
  currency: PropTypes.string.isRequired,
  goldCoins: PropTypes.object.isRequired,
  sweepCoins: PropTypes.object.isRequired,
  gameplay: PropTypes.bool.isRequired,
}
