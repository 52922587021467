import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as common from '@rushplay/common'
import styled from '@emotion/styled'
import { getLocks } from '@rushplay/compliance/locks'
import { lazy as loadable } from '@loadable/component'

import { Footer } from '../footer'
import { MainMenu } from '../main-menu'
import { PrivateRoute } from '../private-route'
import { useScrollToTop } from '../use-scroll-to-top'

const AccountVerification = loadable(() => import('../account-verification'))
const Faq = loadable(() => import('../faq'))
const LockPageContainer = loadable(() => import('../lock-page-container'))
const Logout = loadable(() => import('../logout'))
const StaticPage = loadable(() => import('../static-page'))
const TermsAndConditions = loadable(() => import('../terms-and-conditions'))

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`

export function Locks() {
  const [ref] = useScrollToTop()
  const locks = useSelector(state => getLocks(state.locks))
  const location = ReactRouter.useLocation()

  return (
    <React.Fragment>
      <ReactRouter.Route path="/" component={MainMenu} />
      <Wrapper flexGrow="1" overflowX="hidden" ref={ref}>
        <common.Box
          minHeight={[
            'calc(var(--window-inner-height, 100vh) - 50px)',
            '100vh',
          ]}
          display="flex"
        >
          <ReactRouter.Switch>
            <ReactRouter.Redirect
              exact
              from="/"
              to={{
                pathname: `/locks/${R.head(locks)}`,
                search: location.search,
              }}
            />
            <ReactRouter.Route
              path="/(about|cookie-policy|privacy-policy|responsible-gaming|security)"
              component={StaticPage}
            />

            <PrivateRoute path="/my-identity" component={AccountVerification} />

            <ReactRouter.Route path="/faq" component={Faq} />

            <ReactRouter.Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <PrivateRoute path="/locks/:lock" component={LockPageContainer} />
            <PrivateRoute path="/logout" component={Logout} />
            <ReactRouter.Redirect
              to={{ pathname: '/', search: location.search }}
            />
          </ReactRouter.Switch>
        </common.Box>
        <Footer />
      </Wrapper>
    </React.Fragment>
  )
}

// For @loadable/component
export default Locks
