import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import * as emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { useGameInfoUrlQuery } from './use-game-info-url-query'

export function baseStyles(props) {
  // I'm writing them manually because you can't call functions inside css
  return emotion.css`
    display: flex;
    align-items: center;
    font-size: ${props.theme.fontSizes[3]}px;
    padding: 14px;
    font-weight: bold;
    white-space: nowrap;
    color: 'menu-item';
    transition: .3s;
    border-radius: 4px;
    user-select: none;

    ${css({
      margin: ['4px', null, null, '8px'],
    })}

    &.active {
      background: #0D101499;
    }


    &:hover {
      color: white;
    }
  `
}

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${t.color('disabled')};
  opacity: 0.85;
  cursor: not-allowed;
  pointer-events: none;
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
`

export function MenuItem(props) {
  const Icon = props.icon || null
  const gameInfoQuery = useGameInfoUrlQuery()

  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        {props.icon ? (
          <common.Box mr={0}>
            <Icon />
          </common.Box>
        ) : null}
        <common.Box fontSize={3} display="inline-flex" fontFamily="head">
          {props.children}
        </common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink
      data-testid={props.testId}
      to={props.to}
      isActive={match => {
        if (gameInfoQuery.referrer) {
          return (
            R.equals(gameInfoQuery.referrerPath, props.to) ||
            R.equals(`/${gameInfoQuery.referrer}`, props.to)
          )
        }
        // We don't want loyalty-program to be active
        if (R.test(/loyalty-program=cashback/, props.to)) {
          return
        }

        return match
      }}
      exact={props.exact}
      onClick={props.onClick}
    >
      {props.icon ? (
        <common.Box mr={0}>
          <Icon />
        </common.Box>
      ) : null}
      <common.Box fontSize={3} display="inline-flex" fontFamily="head" flex="1">
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.func,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
